/** @format */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@400;500;600;700;800&display=swap");

.theme-dark {
  --background: #181a20;
  --body: #000;
  --navBg: #000;
  --heroBg: linear-gradient(180deg, rgba(0, 0, 0, 0.664) 0%, #000);
  --textColor: #e1e2e3;
  --cardBg: #1e2329;
  --text: #fff;
  --cardHover: #110f0f;
  --filter: invert(0);
  --active-card: #1d1d1d;
  --modalBg: #151515;
}
.theme-light {
  --background: #fff;
  --body: #fff;
  --navBg: whitesmoke;
  --heroBg: linear-gradient(180deg, rgba(255, 255, 255, 0.664) 0, #ffffff);
  --textColor: #000;
  --cardBg: #ffffff;
  --text: #000;
  --cardHover: #fafafa;
  --modalBg: #ffffff;

  --filter: invert(1);
}
$Roboto: "Roboto", sans-serif;
$Fjalla: "Fjalla One", sans-serif;
$OpenSans: "Open Sans", sans-serif;
$Montserrat: "Montserrat", sans-serif;
::-webkit-scrollbar-track {
  background: var(--background) !important;
}
::-webkit-scrollbar-thumb {
  background: #fcd535 !important;
}
::-webkit-scrollbar {
  width: 5px;
}
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}
body {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  background: var(--body) !important;
}

// Top div
.top_div {
  .inner_div {
    cursor: pointer;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    img {
      width: 18px;
      height: 16px;
    }
    h3 {
      color: #38a6fa;
      text-transform: uppercase;
      font-size: 15px;
      margin: 0 8px;
      font-weight: bold;
      font-family: "Roboto";
      letter-spacing: 0.5px;
    }
    p {
      margin: 0 8px;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
    a {
      margin: 0 8px;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #38a6fa;
    }
  }
}

.navbar {
  .navbar-brand {
    // display: none !important;
  }
  font-family: $Roboto;
  .nav-link {
    font-size: 17px;
    padding: 16px 18px;
    color: var(--textColor) !important;
    font-weight: 600;
    &:hover {
      color: #fcd535 !important;
    }
  }
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid var(--textColor);
  transition: all 300ms linear;
}
.btn_section {
  ul {
    list-style: none;
  }
}

.btn-style {
  border-radius: 5px;
  background: #fcd535;
  font-weight: 600;
  padding: 10px 30px;
  font-size: 1.25rem;
  border: 0 !important;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3);
    background: #fcd535ca;
  }
}
.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--textColor);
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
}
.show-navd {
  display: block !important;
}
.show-hide {
  display: none !important;
}
.navd {
  background-color: #000;
  width: 20%;
  border: 4px solid #fff;
  border-radius: 15px;
  position: absolute;
  li {
    list-style: none !important;
    a {
      text-decoration: none;
      &:hover {
        color: #fcd535 !important;
      }
    }
    &:hover {
      color: #fcd535 !important;
    }
    .active {
      color: var(--textColor);

      &:hover {
        color: #fcd535;
      }
    }
  }
  h3 {
    color: #fff;
    font-size: 34px;
    font-family: "Roboto";
    font-weight: 700;
  }
  p {
    color: #fff;
    font-size: 24px;
    font-family: "Roboto";
  }

  .nav_hov {
    li {
      color: #fff;
      margin: 10px 0;
      font-size: 18px;
      font-family: "Roboto";
      font-weight: 700;
    }
  }
}

//   Navbar End
.about-uss {
  h2 {
    font-size: 56px;
    font-weight: 700;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
  }
  p {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: var(--textColor);
  }
}
// Hero Section Start
.hero_section {
  background-image: var(--heroBg), url("../public/assets/hero-bg.png");
  // border-radius: 15px;
  // max-width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover !important;
  object-fit: cover !important;
  background-repeat: no-repeat;
  padding: 104px 32px 115px 32px;
  // margin-top: 60px;
  color: var(--text);

  .hero_inner {
    // margin: 60px 30px 0 30px;
    .img_2 {
      img {
        right: 0 !important;
        border-radius: 0 0 20px 0;
      }
    }
    h2 {
      font-size: 56px;
      font-weight: 700;
      color: #fcd535;
      font-family: "Exo 2", sans-serif;
    }
    p {
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      color: var(--textColor);
    }
    h4 {
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }
  }
}
// Hero Section End

// Future Section Start
.future_section {
  background: var(--navBg);

  h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    color: #fcd535;
  }
  p {
    margin: 0%;
    color: var(--text);
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }
  .card {
    border: none;
    text-align: center;
    border-radius: 20px;
    color: var(--textColor);
    cursor: pointer;
    background: var(--cardBg) !important;
    box-shadow: 5px 1px 25px 4px rgba(0, 0, 0, 0.2);
    min-height: 200px !important;
    width: 100% !important;
    transition: all 300ms ease-in-out;
    transform: scale(1);
    &:hover {
      background: var(--cardHover) !important;
      transform: scale(1.1);
    }
    .card-body {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      align-content: center !important;
      flex-direction: column;
      img {
        filter: var(--filter);
      }
      h6 {
        font-size: 20px;
        font-family: "Roboto", sans-serif;
        color: var(--text);
        opacity: 0.8;
        text-align: center;
      }
    }
  }
  .bottom_text {
    p {
      font-size: 16px;
      margin: 50px 0;
      background-color: #eeeeee;
      border-radius: 50px;
      padding: 20px 30px;
      color: #000;
    }
  }
}
// Future Section End+

// Featured Listing Start
.featured_sec {
  h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    color: #fcd535;
  }
  p {
    margin: 0%;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    color: var(--text);
  }
  .card {
    border: none;
    border-radius: 15px;
    height: 25rem;
    cursor: pointer;
    &:hover {
      background-color: #e1e2e3;
      .btn-primary {
        background-color: #000 !important;
        color: #fff;
      }
    }
    img {
      padding: 10px;
      border-radius: 15px;
      height: 16rem;
      width: 100%;
    }
    h5 {
      font-size: 24px;
      font-family: "Roboto", sans-serif;
      color: #000000de;
    }
    p {
      font-size: 16px;
      color: #000000de;
      font-family: "Roboto", sans-serif;
    }
    .card-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      font-size: 13px;
      background-color: #efefef;
      color: #000;
      border: none;
      border-radius: 50px;
    }
  }
}
// Featured Listing End

// Funds Section start
.funds_sec {
  margin: 90px 0;
  .bg_img {
    background-image: url("media/images/bg2.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
  }
  .row {
    padding: 40px 20px 0 20px;
  }
  h2 {
    color: #fff;
    font-size: 40px;
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    text-shadow: 2px 2px 8px #000;
  }
  p {
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    color: #fff;
    text-shadow: 2px 2px 8px #000;
    font-weight: 300;
  }
  .img_main {
    width: 60%;
    img {
      width: 100%;
    }
  }
}
// Funds Section end

// Inventions Section Start
.invention_sec {
  margin: 90px 0;
  padding: 50px 0;
  background-image: url("media/images/bg5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  // opacity: 0.9;
  h2 {
    font-size: 40px;
    font-family: "Exo 2", sans-serif;
    color: #fff;
    font-weight: 700;
  }
  p {
    font-size: 24px;
    font-family: "Roboto", sans-serif;

    color: #fff;
  }
  .row_img {
    img {
      width: 12%;
    }
  }
  .card_main {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14);
    padding: 22px 25px;
    border-radius: 15px;
    background-color: #fff;
    border-left: 8px solid #000;
    h3 {
      font-size: 24px;
      font-family: "Roboto", sans-serif;
      color: #000000de;
      font-weight: 700;
    }
    p {
      font-size: 20px;
      // font-style: italic;
      font-family: "Roboto", sans-serif;

      color: #000000de;
      font-weight: 300;
    }
  }
  .card_right {
    h3 {
      text-align: left;
      font-size: 20px;
      font-family: "Roboto", sans-serif;

      color: #000000de;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      // font-style: italic;
      font-family: "Roboto", sans-serif;
      color: #000000de;
      font-weight: 300;
      text-align: left;
    }
  }
  .card_bottom {
    h4 {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      margin-bottom: 0%;
    }
    h5 {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }
  }
}
// Inventions Section End

// Client Section Start
.client_sec {
  margin: 90px 0;

  h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    color: #fcd535;
  }
  p {
    margin: 0%;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    color: var(--text);
  }

  .card_main {
    box-shadow: 0 3px 5px -1px rgba(255, 255, 255, 0.2),
      0 6px 10px 0 rgba(255, 255, 255, 0.14);
    padding: 15px;
    border-radius: 15px;

    .img_main {
      img {
        width: 5%;
        border-radius: 50px;
      }
    }
    h3 {
      font-size: 20px;
      color: var(--text);
      font-family: "Roboto", sans-serif;
    }
    h4 {
      font-size: 16px;
      color: var(--text);
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }
    p {
      font-size: 21px;
      font-family: "Roboto", sans-serif;
      color: var(--text);
      font-weight: 300;
    }
  }
}
.dot {
  background-color: #000 !important;
}
.thumbs-wrapper {
  display: none !important;
}
.carousel-status {
  display: none !important;
}
// Client Section End

// Escrow Section Start
.Escrow_sec {
  margin: 90px 0;
  .bg_img {
    background-image: url("media/images/bg3.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
  }
  .row {
    padding: 40px 20px 0 20px;
  }
  h2 {
    color: #fff;
    font-size: 40px;
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    text-shadow: 2px 2px 8px #000;
  }
  p {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #fff;
    text-shadow: 2px 2px 8px #000;
    font-weight: 300;
  }
  .img_main {
    width: 60%;
    img {
      width: 100%;
    }
  }
}
// Escrow Section End

// ========================================================
//  ======================= Modal =========================
// ========================================================

.modal-content {
  background: var(--modalBg) !important;
  border: 1px solid #d5a419 !important;

  .modal-body {
  }
  h4 {
    color: #d5a419;
    font-family: "Exo 2", sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    color: var(--text);
    font-weight: 300;
  }
  ul li {
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    color: var(--text);
    font-weight: 300;
    margin-top: 10px;
  }
}
.btn-close {
  display: block;
  box-shadow: none !important;
  transform: rotate(180deg) !important;
  padding: 7px 5px !important;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat !important;
}
.modal-header {
  border-bottom: 0px !important;
}
.modal-title {
  padding-left: 28px;
  padding-right: 28px;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-family: "Exo 2", sans-serif;
  color: #d5a419;
}
.modal-footer {
  border-top: 0px !important;
  p {
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    color: var(--text);
    font-weight: 300;
    strong {
      color: #d5a419;
      font-weight: 900;
    }
  }
}

// Section Start
.section {
  margin: 80px 0;

  h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    color: #fcd535;
  }
  p {
    margin: 0%;
    color: var(--text);
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }

  h4 {
    font-size: 24px;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #fcd535;
  }
  .active-card {
    background: var(--active-card);
  }
  .card_main {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14);
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;

    .card_left {
      img {
        width: 15%;
        height: 15%;
        filter: var(--filter);
      }
    }
    .card_right {
      h3 {
        font-size: 24px;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: var(--text);
      }

      p {
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
      }
      span {
        text-decoration: none;
        color: #fcd535;
        font-size: 16px;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
      }
    }
  }
}

// Section End
/*right*/
.contact-right {
  margin-right: 0px;
  /* padding-top: 3rem; */
}
/*this is using 'hover'*/

.contact-right button {
  margin-left: -20px;
}

.contact-right input {
  height: 48px;
  width: 0px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.8s;
  border: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  padding-left: 20px;
}

.contact-right:hover input {
  width: 250px;
}

.contact-right input::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

// NewsLetter Section Start
.newsletter_sec {
  margin: 90px 0;
  .bg_img {
    background-image: url("media/images/bg4.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
  }

  h2 {
    color: #fff;
    font-size: 40px;
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    text-shadow: 2px 2px 8px #000;
  }
  p {
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    color: #fff;
    text-shadow: 2px 2px 8px #000;
    font-weight: 300;
  }
  .img_main {
    width: 80%;
    img {
      width: 80%;
    }
  }
  .trs {
    transform: rotate(15deg);
  }
}
// NewsLetter Section End

.faqs {
  position: relative;
  height: auto;
  h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    color: #fcd535;
  }
  p {
    margin: 0%;
    color: var(--text);
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }
}

.styles_faq-row-wrapper__3vA1D {
  background: transparent !important;
}
.faq-row {
  background: var(--text);
  border-bottom: none !important;
  border-radius: 10px !important;
  box-sizing: content-box;
  box-shadow: 0px 4px 8px rgba(108, 108, 108, 0.05);
  margin: 20px auto !important;
  padding-left: 20px !important;
  width: 75% !important;
  font-family: "Roboto", sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 130%;
  color: var(--cardBg) !important;
}
.row-content-text {
  border-radius: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  text-align: left;
  white-space: normal;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 170%;
  -o-object-fit: fill;
  object-fit: fill;
}
// Cards Section Start
.card_sec {
  margin: 60px 0;
  .bg_img1 {
    background-image: url("media/images/f1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
    height: 17rem;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .bg_img2 {
    background-image: url("media/images/f2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
    height: 17rem;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .bg_img3 {
    background-image: url("media/images/f3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
    height: 17rem;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  h2 {
    color: #fff;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-shadow: 2px 2px 8px #000;
  }
  a {
    margin: 0%;
    color: #000;
    background-color: #e0e0e0;
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    text-decoration: none;

    &:hover {
      background-color: #fff;
    }
  }
}
// Cards Section End

// footer section Start
.footer {
  background: #111;
  h4 {
    font-size: 16px;
  }
  p {
    color: #cccc;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin: 15px;
  }
  a:hover {
    color: #fcd535 !important;
  }
}
// footer section End
.fixed-icons {
  position: fixed;
  top: 32%;
  left: 25px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fixed-icons a {
  color: var(--text) !important;
}
.fixed-icons a:hover {
  color: #fcd535 !important;
}
// Login Section start

.card {
  background: linear-gradient(#191518, #030304) !important;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin-top: 80px;
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
  }
  input {
    border: 2px solid #737da7 !important;
    border-radius: 6px !important;
    padding: 13px 20px;
    max-width: 100%;
    font-family: "Exo 2", sans-serif;
  }
  textarea {
    border: 2px solid #737da7 !important;
    border-radius: 6px !important;
    padding: 13px 20px;
    max-width: 100%;
    font-family: "Exo 2", sans-serif;
  }
  label {
    color: #fff;
    font-family: "Exo 2", sans-serif;
    font-size: 17px;
    padding-bottom: 5px;
    font-weight: 400;
    margin-left: 12px;
  }
}

.botm_link {
  color: white !important;
  font-size: 16px;
  font-weight: 500;
  font-family: "Exo 2", sans-serif;
  a {
    text-decoration: none !important;
    color: #fcd535 !important;
    font-style: italic;
    margin-left: 20px;
    font-weight: 700;
    font-size: 15px;
  }
}
// Login Section end

// Contact Section Start
.contact_sec {
  margin: 80px 0;
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
  }
  .img_main {
    width: 60%;
    margin: 80px;
  }
}
// Contact Section End
.about_sec {
  margin: 90px 0;
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
    text-align: center;
  }
}
// term & Conditon Section start
.termConditon_sec {
  margin: 90px 0;
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
    text-align: center;
  }
  p {
    margin-top: 30px;
    color: #fff;
    font-size: 16px;
    font-family: "Exo 2", sans-serif;
  }
  ul {
    li {
      color: #fff;
      a {
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}
// term & Conditon Section end

// Privacy Section Start
.Privacy_sec {
  margin: 90px 0;
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
    text-align: center;
  }
  h5 {
    color: #fff;
    font-size: 16px;
    font-family: "Exo 2", sans-serif;
  }
  h4 {
    color: #fcd535;
  }
  p {
    color: #fff;
    font-size: 16px;
    font-family: "Exo 2", sans-serif;
  }
  ul {
    li {
      color: #fff;
      margin: 20px 0;
      span {
        font-weight: bold;
      }
    }
  }
}
// Privacy Section End

// Blog & Vlog Section Start
.blog_sec {
  margin: 80px 0;
  .bg_img {
    background-image: linear-gradient(180deg, rgba(42, 90, 242, 0) 0, #000),
      url("../public/assets/hero-bg.png");
    // border-radius: 15px;
    max-width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover !important;
    object-fit: cover !important;
    background-repeat: no-repeat;
    padding: 104px 32px 115px 32px;
    // margin-top: 60px;
    color: #fff;
  }
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #fcd535;
    font-family: "Exo 2", sans-serif;
    text-align: center;
  }
  .batn {
    padding: 10px 12px !important;
  }
  .card {
    color: #fff;
    font-family: "Exo 2", sans-serif;
    border-radius: 8px !important;
    height: 100%;
    margin: 20px 0;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: translate(0, -10px);
    }
    .card_img {
      width: 100%;
      height: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .card_img2 {
      background-image: linear-gradient(180deg, rgba(42, 90, 242, 0) 0, #000),
        url("../public/assets/hero-bg.png");
      max-width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover !important;
      object-fit: cover !important;
      background-repeat: no-repeat;
      border-radius: 8px;
      color: #fff;
    }
    h2 {
      font-family: "Roboto";
      font-weight: 700;
      letter-spacing: 0.9px;
      font-size: 35px;
    }
    .card_footer2 {
      position: absolute;
      bottom: 0;
      margin-bottom: 20px;
    }
  }
}
// Blog & Vlog Section End
.video_main {
  iframe {
    width: 100%;
    height: 400px;
    border: 1px solid #fcd535;
  }
  .bg_img2 {
    margin: 80px 0;
    background-image: linear-gradient(180deg, rgba(42, 90, 242, 0) 0, #000),
      url("../public/assets/hero-bg.png");
    // border-radius: 15px;
    max-width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover !important;
    object-fit: cover !important;
    background-repeat: no-repeat;
    padding: 104px 32px 115px 32px;
    // margin-top: 60px;
    color: var(--text);
    h3 {
      font-size: 40px;
      font-weight: 600;
      color: #fcd535;
      font-family: "Exo 2", sans-serif;
      text-align: center;
    }
  }

  h1 {
    font-size: 30px;
    font-family: $Roboto;
    font-weight: 500;
    color: var(--textColor);

    text-align: center;
    span {
      font-size: 35px;
      font-weight: 600;
      color: #000;
      background: #fcd535;
    }
  }
}
.timeline_area {
  position: relative;
  z-index: 1;
  color: var(--text);
}
.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area {
    padding-left: 100px;
  }
}
.single-timeline-area .timeline-date {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 100px;
    transform: rotate(90deg);
  }
  .single-timeline-area .timeline-date::after {
    position: absolute;
    width: 3px;
    height: 100%;
    content: "";
    background-color: #ebebeb;
    top: 0;
    right: 30px;
    transform: rotate(90deg);

    z-index: 1;
  }
  .single-timeline-area .timeline-date::before {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #f1c40f;
    content: "";
    top: 50%;
    right: 26px;
    z-index: 5;
    margin-top: -5.5px;
    transform: rotate(90deg);
  }
}
.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: #ebebeb;
  top: 0;
  right: 30px;

  z-index: 1;
}
.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f1c40f;
  content: "";
  top: 50%;
  right: 26px;
  z-index: 5;
  margin-top: -5.5px;
}
.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #f1c40f;
  font-size: 13px;

  font-weight: 500;
}
.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 30px 30px 25px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid var(--textColor);
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
    padding: 20px;
  }
}
.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}
.single-timeline-area .single-timeline-content .timeline-icon i {
  color: var(--text);
  line-height: 30px;
}
.single-timeline-area .single-timeline-content .timeline-text h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
}
.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
  background-color: #020710;
}
.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
  color: #3f43fd;
}
.timeline_area {
  h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
    color: #fcd535;
  }
}
@media only screen and (max-width: 992px) {
  .navbar {
    margin-top: 0px !important;
    background: #000 !important;
    .navbar-brand {
      width: 30% !important;
      display: block !important;
    }
  }
  .col-sm-null {
    display: none !important;
  }
  .btn-style {
    width: 100% !important;
    margin-top: 10px !important;
    text-align: center;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .about-uss {
    p {
      text-align: center !important;
    }
    h2 {
      font-size: 32px;
    }
  }
  .about_sec {
    text-align: center !important;
  }
  .fixed-icons {
    display: none;
  }
  .top_div {
    display: none;
  }
  .video_main {
    iframe {
      height: 220px;
    }
  }
  .col-sm-null {
    display: none !important;
  }
  .future_section {
    h2 {
      font-size: 30px;
    }
  }
  .btn-style {
    width: 100% !important;
    margin-top: 10px !important;
    text-align: center;
    display: block;
  }
  .navbar {
    margin-top: 0px !important;
    background: var(--navBg) !important;

    .navbar-brand {
      width: 30% !important;
      display: block !important;
    }
  }
  .hero_section {
    margin-top: 0%;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    .hero_inner {
      // text-align: center;
      .img_2 {
        img {
          bottom: 0 !important;
          width: 100% !important;
          margin-bottom: -80px !important;
        }
      }
      h2 {
        font-size: 35px !important;
        text-align: center;
        margin-top: 73px;
      }
    }
  }
  .form {
    width: 100%;
    .head {
      .form_top {
        display: flex;
        justify-content: center;
      }
      img {
        width: 10% !important;
      }
      h2 {
        font-size: 16px !important;
      }
      .btn_main {
        text-align: center;
        a {
          padding: 8px 50px !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

// @media screen and (min-width: 768px) {
//   .hero_section{
//     display: flex;
//     justify-content: center;
//   .hero_inner{
//     .img_2{
//       img{
//        bottom: 0 !important;
//        width: 75% !important;
//        margin-bottom: -35.4rem !important;
//       }
//      }
//     h2{
//       font-size: 35px !important;
//     }
//   }
// }

// }
